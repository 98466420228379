<template>
  <div class="header">
    <div class="header-nav">
      <div class="nav-title" @click="toHome">
        <!-- logo -->
        <img class="logo" :src="logoImg" />
        <span>慧学云</span>
      </div>
      <!--一级菜单导航-->
      <div ref="navs" class="nav-list">
        <t-space :size="24">
          <div
            v-for="item in navList"
            :key="item.id"
            class="nav-item"
            :class="{'active': currentNavId === item.id}"
            @click="handleClickNav(item)">
            {{ item.label }}
          </div>
        </t-space>
      </div>
       <!-- 未登录 -->
      <div  class="login" :class="{ 'mar-l': access_token}">
        <div v-if="access_token">
          <t-popup overlayClassName="popover"  :showArrow="true" placement="bottom-right">
            <t-avatar size="40px" :image="userInfo.avatar ? userInfo.avatar : userAvatar"></t-avatar>
            <div class="member silver-member" v-if="vipMember === 'silver_member'"  />
            <div class="member gold-member" v-if="vipMember === 'gold_member'"  />
            <div class="member diamond-member" v-if="vipMember === 'diamond_member'"  />
            <template #content>
              <div class="user-info">
                <t-avatar size="40px" :image="userInfo.avatar ? userInfo.avatar : userAvatar"></t-avatar>
                <div class="info-detail">
                  <div class="info-name">{{ userInfo.name }}</div>
                  <div class="info-school">{{(userInfo.schools && userInfo.schools.length===1) ? (userInfo.school) : (userInfo.institutionName)}}</div>
                </div>
              </div>
              <t-divider />
              <div class="operation">
                <div class="operation-item" @click="toSpace"><img class="operation-img" :src="require('@/assets/space.svg')" />我的空间</div>
                <div class="operation-item" @click="logout"><img class="operation-img" :src="require('@/assets/logout.svg')" />退出登录</div>
              </div>
            </template>
          </t-popup>
        </div>
        <!-- <div v-else @click="login">
          <t-button  theme="primary" shape="round" variant="base"><span class="btn"  >登录/注册</span></t-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup>
/* 工具 */
import { ref, watch, onMounted,  getCurrentInstance } from 'vue';
import { DialogPlugin } from 'tdesign-vue-next';
import { getStore, clearStore, setStore } from "@/util/store"
import mitt from "mitt";

import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { getSignOut ,getVipHeader} from "@/api/user.js";

/* 变量 */
const { $bus }  = getCurrentInstance().appContext.config.globalProperties
const logoImg = require("@/assets/logo.svg")

let access_token = ref('') // access_token
let userInfo = ref({}) // userInfo
let accessInfo = ref({}) // 获取accessInfo

const navList = [
  {
    label: '首页',
    id: 1,
    path: '/home'
  },
  {
    label: '资源',
    id: 2,
    path: '/resource'
  },
  /* {
    label: '会员',
    id: 3,
    path: '/member'
  },
  {
    label: '群组',
    id: 4,
    path: '/group'
  },
  {
    label: '应用',
    id: 5,
    path: '/application'
  } */
]

const envList = ['dev', 'test', 'pre']
const env_name = envList.find(v => {
  return location.host.includes(v)
}) || ''
const currentNavId = ref(1);
const userAvatar = require("@/assets/defaultAvatar.png")

watch(
  () => route.path,
  (value) => {

    const data = navList.find(x =>   x.path === value )
    if (data && data.id) {
      currentNavId.value = navList.find(x =>   x.path === value ).id
    }
    // 应用详情
    if (value === '/detail') {
      currentNavId.value = 5
    }
    // 会员
    if (['/choiseVip','/detailVip'].includes(value)) {
      currentNavId.value = 3
    }
  },
  {
    deep: true,
    immediate: true
  }
)

onMounted(() => {
  $bus.on('saveUserInfo', () => {
    saveData()
  })

  if (getStore({ name: "access_token" })) {
    saveData()
  }

  $bus.on('update', () => {
    saveData()
  })
  
})
// 会员头像框
const vipMember = ref(null)
const getVip = (accessInfo) => {
  if (accessInfo && accessInfo.user_id) {
    getVipHeader(accessInfo.user_id).then(res => {
      if (res.data.code === 0) {
        vipMember.value = res.data.data
      }
    })
  }
}

const saveData = () => {
  access_token.value = getStore({ name: "access_token" }) 
  userInfo.value = getStore({ name: "userInfo" })
  accessInfo.value = getStore({ name: "accessInfo" })
  getVip(accessInfo.value)
}

const handleClickNav = (item) => {
  currentNavId.value = item.id;
  router.push(item.path);
}




// 登录
const login = () => {
  setStore({ name: "routePath", content: route.path }) 
  setStore({ name: "routeQuery", content: route.query }) 
  window.location.href = (env_name?'//'+env_name+'login.huixuecloud.cn':'//login.zhixuehuijiao.cn') + '?redirect_url=' + window.location.origin
}

// 退出
const logout = () => {
  const DialogInstance = DialogPlugin.confirm({
    theme: 'warning',
    header: '是否确认退出？',
    confirmBtn: "是",
    cancelBtn: "否",
    onConfirm: async () => {
      getSignOut().then(res => {
        if (res.data.code === 0) {
          DialogInstance.destroy()
          clearStore()
          location.reload();
        }
      })
    }
  })
}

// 去空间
const toSpace = () => {
  window.open((env_name?'//'+env_name+'main.huixuecloud.cn':'//web.zhixuehuijiao.cn') + '?token='+access_token, '_blank')
}

// 去首页
const toHome = () => {
  router.push('/home');
}
</script>
<style lang="scss" scoped>
.header{
  width: 100%;
  height: 70px;
  font-family: Sans Bold;
  .header-nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    border-bottom: 1px solid  rgba(170, 170, 170, 0.2);
    .nav-title {
      display: flex;
      color: #1A1A1A;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      align-items: center;
      cursor: pointer;

      .logo {
        width: 32px;
        height: 32px;
        margin: 0 13px 0 0;
      }
    }

    .nav-list{
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 100%;
      .nav-item {
        list-style: none;
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        color: #1A1A1A;
        cursor: pointer;
        &.active {
          color: #3A63F3;
        }
      }
    }
    .login{
      position: relative;
      margin-left: 23px;
      .btn{
        font-family: Sans Bold;
      }
    }
    .mar-l{
      margin-left: 77px;
    }
  }
}
.member {
  position: absolute;
  width: 48px;
  height: 48px;
  margin-left: -4px;
  cursor: pointer;
  top: -4px;
  z-index: 100;
}
.silver-member {
  background: url('~@/assets/silver_member.svg');
  background-size: 100%;
}
.gold-member {
  background: url('~@/assets/gold_member.svg');
  background-size: 100%;
}
.diamond-member {
  background: url('~@/assets/diamond_member.svg');
  background-size: 100%;
}
</style>
<style lang="scss">
  .popover{
    width: 176px;
    .t-popup__content{
      padding: 12px;
    }
    .user-info{
      display: flex;
      align-items: center;
      .info-detail{
        margin-left: 10px;
        flex:1;
        .info-name{
          font-family: Sans Bold;
          color: #1A1A1A;
          font-size: 14px;
          font-weight: bold;    
          margin-bottom: 4px;
        }
        .info-school{
          font-family: Sans Regular;
          color: #999999;
          font-size: 12px;
          font-weight: normal;   
          word-break: break-all;   
        }
      }
    }
    .t-divider{
      margin: 12px 0;
    }
    .operation{
      .operation-item{
        display: flex;
        font-family: Sans Medium;
        font-size: 14px;
        font-weight: 500;
        color: #1A1A1A;
        margin-bottom: 12px;
        cursor: pointer;
      }
      .operation-item:last-child{
        margin-bottom: 0px;
      }
      .operation-img{
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
    }
  }
</style> 
